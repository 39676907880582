import api from "@/api";
import _ from "lodash";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICreateProjectDto, IProjectDto, IUpdateProjectDto } from "common/dist/dtos/ProfileDto";
import { IServiceInfoDto } from "common/dist/dtos/service/ServiceDto";

export interface IProfileState {
    image: string;
    city: string;
    country: string;
    title: string;
    instagram: string | null;
    linkedin: string | null;
    twitter: string | null;
    youtube: string | null;
    projects: IProjectDto[] | null;
    services: IServiceInfoDto[] | null;
}

const initialState: IProfileState = {
    image: "http://image.com",
    city: "City",
    country: "Country",
    title: "Software Engineer",
    instagram: null,
    youtube: null,
    twitter: null,
    linkedin: null,
    projects: [],
    services: []
};

export const updateProfile = createAsyncThunk("profile/update", async (payload: Partial<IProfileState>, store) => {
    await api.updateProfile(payload);
    store.dispatch(profile.actions.init(payload));
});

export namespace service {
    export const fetch = createAsyncThunk("profile/service/fetch", async (userId: number) => {
        return await api.endpoints.service.list(userId);
    });
}

export namespace project {
    export const update = createAsyncThunk(
        "profile/project/update",
        async (payload: { id: number; dto: IUpdateProjectDto }, store) => {
            const session = window.session;
            await api.endpoints.project.update(payload.id, payload.dto);
            await store.dispatch(fetch(session.user.userId));
        }
    );

    export const delete_ = createAsyncThunk("profile/project/delete", async (projectId: number) => {
        await api.endpoints.project.delete(projectId);
        return projectId;
    });

    export const create = createAsyncThunk("profile/project/add", async (payload: { dto: ICreateProjectDto }) => {
        return await api.endpoints.project.create(payload.dto);
    });

    export const fetch = createAsyncThunk("profile/project/fetch", async (userId: number) => {
        return await api.endpoints.project.list(userId);
    });
}

export const profile = createSlice({
    name: "profile",
    initialState,
    reducers: {
        init(state, action: PayloadAction<Partial<IProfileState>>) {
            Object.assign(state, _.omitBy(action.payload, _.isUndefined));
        }
    },
    extraReducers(builder) {
        // Service
        builder
            .addCase(service.fetch.pending, (state, res) => {
                state.services = null;
            })
            .addCase(service.fetch.fulfilled, (state, res) => {
                state.services = res.payload;
            });

        // Project
        builder.addCase(project.delete_.fulfilled, (state, res) => {
            _.remove(state.projects, (p) => p.id === res.payload);
        });

        builder
            .addCase(project.fetch.pending, (state) => {
                state.projects = null;
            })
            .addCase(project.fetch.fulfilled, (state, res) => {
                state.projects = res.payload;
            });

        builder.addCase(project.create.fulfilled, (state, res) => {
            state.projects.push(res.payload);
        });
    }
});
