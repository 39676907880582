import { Endpoint, HttpService, IApi } from "./util";
import { ICategoryWithParentDto } from "common/dist/dtos/ICategoryDto";

export interface ICategoryApi extends IApi {
    get(): Promise<ICategoryWithParentDto[]>;

    topLevelCategories(): Promise<ICategoryWithParentDto[]>;

    getById(id: number): Promise<ICategoryWithParentDto>;
}

export class CategoryApi extends HttpService implements ICategoryApi {
    @Endpoint()
    async get(): Promise<ICategoryWithParentDto[]> {
        return await this.getRequest("/api/category");
    }

    @Endpoint()
    async topLevelCategories(): Promise<ICategoryWithParentDto[]> {
        return await this.getRequest("/api/category/topLevelCategories");
    }

    @Endpoint()
    async getById(id: number): Promise<ICategoryWithParentDto> {
        return await this.getRequest(`/api/category/${id}`);
    }
}
