import { runInAction, observable } from "mobx";

export interface ITaskHooks<X, R> {
    pending?(): R;
    resolved?(v: X): R;
    error?(e: any): R;
}

export interface ITask<X> {
    pending: boolean;

    /** Returns the promise of the task, possibly starting it. */
    fetch: () => Promise<X>;

    /** Returns the promise of the task, possibly starting it. */
    wait: () => Promise<X>;

    /** Matches against the current state of the task, possibly starting it. */
    match<R>(hooks: ITaskHooks<X, R>): R;

    /** Gets the current resolved value of the task or null, possibly starting it. */
    get(): X;
}

export function task<X>(v: () => Promise<X>): ITask<X> {
    const fetch = function () {
        runInAction(() => {
            values.promise = v();
            values.promise
                .then((x: X) => {
                    runInAction(() => {
                        values.resolved = x;
                    });
                })
                .catch((e: any) => {
                    runInAction(() => {
                        values.error = e;
                    });
                });
        });
        return values.promise;
    };

    const run = function () {
        if (!values.promise && typeof window !== "undefined") {
            fetch();
        }
        return values.promise;
    };

    const match = function <R>(hooks: ITaskHooks<X, R>): R {
        run();
        if (values.error !== undefined) {
            return hooks.error?.(values.error) || null;
        } else if (values.resolved !== undefined) {
            return hooks.resolved?.(values.resolved) || null;
        } else {
            return hooks.pending?.() || null;
        }
    };

    const values = observable({
        error: undefined,
        resolved: undefined,
        promise: undefined,
        pending: true
    });

    return {
        async fetch() {
            return fetch();
        },
        async wait() {
            return run();
        },
        get() {
            return match({ resolved: (p) => p });
        },
        match,
        get pending() {
            return !(values.resolved === undefined || values.error === undefined);
        }
    };
}
