import { ISession } from "common/dist/dtos/ISession";

export enum Role {
    Visitor = 0 << 0,
    User = 1 << 0,
    Admin = (1 << 1) | Role.User,
    Professional = (1 << 2) | Role.User,
    Buyer = (1 << 3) | Role.User
}

export const hasRole = (session: ISession, role: Role) => {
    let userRole: Role = 0;
    if (session?.user) {
        userRole |= Role.User;
        if (session.user.professional) {
            userRole |= Role.Professional;
        } else {
            userRole |= Role.Buyer;
        }
        if (session.user.admin) {
            userRole |= Role.Admin;
        }
    }

    if (userRole !== 0 && role === 0) {
        return false;
    }

    return (role & userRole) === role;
};
