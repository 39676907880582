import api from "@/api";
import { ICategoryWithParentDto } from "common/dist/dtos/ICategoryDto";
import _ from "lodash";
import { makeAutoObservable } from "mobx";
import { task } from "./util";

export class CommonStore {
    calendarDetails: boolean;

    fetchServicesFromCategory = (categoryId: number) => task(() => api.endpoints.service.listByCategory(categoryId));

    fetchCalendarDetails = async () => {
        this.calendarDetails = await api.endpoints.common.isCalendarConnected();
    };

    fetchAccountDetails = task(() => api.endpoints.common.getAccountDetails());

    fetchAccountBillingDetails = task(() => api.endpoints.common.getAccountBillingDetails());

    fetchNewTalent = task(() => api.endpoints.professional.listNew());
    fetchPopularTalent = task(() => api.endpoints.professional.listPopular());
    fetchRisingTalent = task(() => api.endpoints.professional.listRising());

    categories: ICategoryWithParentDto[] = [];

    fetchNew = task(() => api.endpoints.service.listNew());
    fetchFavourites = task(() => api.endpoints.service.listFavourites());
    fetchHot = task(() => api.endpoints.service.listHot());

    setCategories(categories: ICategoryWithParentDto[]) {
        this.categories = _.sortBy(categories, (c) => c.id);
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }
}
