import _ from "lodash";
import api, { endpoints } from "@/api";
import { IApplicationDto } from "common/dist/dtos/application/ApplicationDto";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAdminState {
    applications: IApplicationDto[];
}

const initialState: IAdminState = {
    applications: []
};

export const admin = createSlice({
    name: "admin",
    initialState,
    reducers: {
        changeApplications(state, action: PayloadAction<IApplicationDto[]>) {
            state.applications = action.payload;
        }
    }
});

export const rejectApplication = createAsyncThunk(`${admin.name}/reject`, async (payload: number, store) => {
    await endpoints.application.reject(payload);
    await store.dispatch(fetchApplications());
});

export const acceptApplication = createAsyncThunk(`${admin.name}/accept`, async (payload: number, store) => {
    await endpoints.application.accept(payload);
    await store.dispatch(fetchApplications());
});

export const fetchApplications = createAsyncThunk(`${admin.name}/fetch`, async (_payload, store) => {
    const applications = await endpoints.application.list();
    store.dispatch(admin.actions.changeApplications(applications));
});
