import React, { useEffect, useRef, useState } from "react";
import Router, { useRouter } from "next/router";
import { AppProps } from "next/app";
import { Provider as ReduxProvider } from "react-redux";
import { ApplicationInsights, IApplicationInsights } from "@microsoft/applicationinsights-web";
import PlausibleProvider from "next-plausible";
import { deleteCookie, setCookie } from "cookies-next";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import TagManager from "react-gtm-module";
import { getEndpoints } from "@/api";
import { store, wrapper } from "@/common/store";
import { CommonStore } from "@/common/store/common.store";
import { CommonContext, isBrowser } from "@/components/util";
import { Footer } from "@/components/Footer";
import { SessionContext } from "@/common/session.context";
import { hasRole } from "@/common/roles";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { IMetrics, Metrics, MetricsContext } from "@/common/metrics";

import TopBar from "@/components/TopBar";
import Container from "@/components/Container";
import Head from "next/head";
import { ISession } from "common/dist/dtos/ISession";
import { IdentityApi } from "@/api/identity.api";
import { ICategoryWithParentDto } from "common/dist/dtos/ICategoryDto";

import "reflect-metadata";
import "tailwindcss/tailwind.css";
import "antd/dist/antd.less";
import "@/styles/global.css";

declare global {
    interface Window {
        session: ISession;
        appInsights: IApplicationInsights;
    }
}

const initializeGoogleTagManager = () => {
    TagManager.initialize({ gtmId: "GTM-W4W2VGQ" });
};

function MyApp({ Component, pageProps, session, categories }) {
    const router = useRouter();
    const ref = useRef<LoadingBarRef>(null);
    const [sessionState, setSession] = useState<ISession>(session);
    const [metrics] = useState<IMetrics>(() => isBrowser() && new Metrics());

    if (typeof window !== "undefined") {
        window.session = session;
    }

    useEffect(() => {
        initializeGoogleTagManager();
    }, []);

    useEffect(() => {
        const handleRouteChangeStart = () => {
            if (ref && ref.current) ref.current.continuousStart(0, 5);
        };

        const handleRouteChangeComplete = () => {
            if (ref && ref.current) ref.current.complete();
        };

        const handleRouteChangeError = () => {
            if (ref && ref.current) ref.current.complete();
        };

        Router.events.on("routeChangeStart", handleRouteChangeStart);
        Router.events.on("routeChangeComplete", handleRouteChangeComplete);
        Router.events.on("routeChangeError", handleRouteChangeError);

        return () => {
            Router.events.off("routeChangeStart", handleRouteChangeStart);
            Router.events.off("routeChangeComplete", handleRouteChangeComplete);
            Router.events.off("routeChangeError", handleRouteChangeError);
        };
    }, []);

    useEffect(() => {
        if (metrics) {
            if (sessionState) {
                metrics.setSession(sessionState);
            } else {
                metrics.setSession(null);
            }
        }
    }, [metrics, sessionState]);

    if (session) {
        session.logout = () => {
            setSession(null);
        };
    }

    const [store] = useState(() => {
        const store = new CommonStore();
        store.setCategories(categories);
        return store;
    });

    useEffect(() => {
        if (router.asPath !== "/signup" && router.asPath !== "/login") {
            deleteCookie("before_signup", { path: "/" });
        }
    }, [router.asPath]);

    return (
        <PlausibleProvider domain="vettted.com">
            <SessionContext.Provider value={sessionState}>
                <MetricsContext.Provider value={metrics}>
                    <CommonContext.Provider value={store}>
                        <Head>
                            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                            <link rel="manifest" href="/site.webmanifest" />
                            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                            <meta name="msapplication-TileColor" content="#da532c" />
                            <meta name="theme-color" content="#ffffff" />
                            {/* Google tag (gtag.js) */}
                            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16479756367"></script>
                            <script
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        window.dataLayer = window.dataLayer || [];
                                        function gtag(){dataLayer.push(arguments);}
                                        gtag('js', new Date());
                                        gtag('config', 'AW-16479756367');
                                    `
                                }}
                            />
                            <script
                                type="text/javascript"
                                dangerouslySetInnerHTML={{
                                    __html: `
                                       (function(c,l,a,r,i,t,y){
                                          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                                      })(window, document, "clarity", "script", "p6kfnxizzu");
                                    `
                                }}
                            />
                        </Head>
                        <LoadingBar color="#8e80ff" ref={ref} />
                        {(Component as any).standalone ? (
                            <Component {...pageProps} />
                        ) : (
                            <div className="flex flex-col h-screen" style={{ backgroundColor: "#100E26" }}>
                                <TopBar />
                                <div style={{ backgroundColor: "#f0f2f5" }}>
                                    <Container className="flex-1 py-5 sm:py-10 justify-self-center w-full min-h-0">
                                        <Component {...pageProps} />
                                    </Container>
                                </div>
                                <Footer />
                            </div>
                        )}
                    </CommonContext.Provider>
                </MetricsContext.Provider>
            </SessionContext.Provider>
        </PlausibleProvider>
    );
}

class Wrapper extends React.Component<AppProps & { session: ISession; categories: ICategoryWithParentDto[] }> {
    static async getInitialProps({ Component, ctx }) {
        let endpoints = getEndpoints(ctx);

        const { ref } = ctx.query;
        if (ref) {
            setCookie("ref", ref, { req: ctx.req, res: ctx.res, maxAge: 60 * 60 * 24 * 7, path: "/" });
        }

        // Remove from google search
        if (ctx.req && process.env.APP_ENV === "development") {
            ctx.res.setHeader("X-Robots-Tag", "noindex");
        }

        // If we're in the server
        if (ctx.req) {
            const identityApi = new IdentityApi(ctx);
            try {
                const refreshToken = await identityApi.refreshToken();
                if (refreshToken.token) {
                    setCookie("access_token", refreshToken.token, { req: ctx.req, res: ctx.res, path: "/" });
                    // Generating new session context endpoints
                    endpoints = getEndpoints(ctx);
                }
            } catch (e) {}
        }

        // Always fetch the user data
        let session = null;
        try {
            session = await endpoints.identity.session();
        } catch (e) {}

        if (Component.role !== undefined && !hasRole(session, Component.role)) {
            const destination = Component.destination || "/login";

            if (destination == "/login") {
                setCookie("before_signup", ctx.req?.url || Router.asPath, { req: ctx.req, res: ctx.res, path: "/" });
            }
            if (ctx.req) {
                ctx.res.writeHead(302, { Location: destination });
                ctx.res.end();
            } else {
                await Router.replace(destination);
            }

            return {};
        }

        // Making sure getInitialProps is being called for child components
        let pageProps = {};
        if (Component?.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }

        const categories = await endpoints.category.get();

        return {
            session,
            pageProps,
            categories
        };
    }

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: process.env.APPINSIGHTS_INSTRUMENTATIONKEY
                }
            }).loadAppInsights();
        }
    }

    componentDidCatch(error: Error) {
        window.appInsights?.trackException({ exception: error });
    }

    render() {
        return (
            <ReduxProvider store={store}>
                <Head>
                    <title>Vettted - Hire Vetted SEO Experts - SEO Marketplace</title>
                    <meta name="title" content="Vettted - Hire Vetted SEO Experts - SEO Marketplace" />
                    <meta
                        name="description"
                        content="Vettted is an SEO marketplace with pre-vetted SEO experts offering services to scale your business' organic traffic. Try a service today!"
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" name="og:url" content="https://vettted.com/" />
                    <meta property="og:title" name="og:title" content="Hire Vetted SEO experts - Vettted" />
                    <meta
                        property="og:description"
                        name="og:description"
                        content="A Vetted and Verified Experience To Scale Your SEO Strategy. Hire SEO experts to take our online marketing to the next level."
                    />
                    <meta property="og:image" name="og:image" content={`${process.env.DOMAIN}/vettted-meta.png`} />
                    <meta property="twitter:card" name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" name="twitter:url" content="https://vettted.com/" />
                    <meta property="twitter:title" name="twitter:title" content="Hire Vetted SEO experts - Vettted" />
                    <meta
                        property="twitter:description"
                        name="twitter:description"
                        content="SEO Marketplace for businesses and agencies to outsource their marketing efforts."
                    />
                    <meta
                        property="twitter:image"
                        name="twitter:image"
                        content={`${process.env.DOMAIN}/vettted-meta.png`}
                    />

                    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                    <link rel="manifest" href="/site.webmanifest" />
                    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                    <meta name="msapplication-TileColor" content="#da532c" />
                    <meta name="theme-color" content="#ffffff" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
                </Head>
                <MyApp {...this.props} />
                <TawkMessengerReact widgetId="1fbl70rrk" propertyId="61009fdad6e7610a49ad4de3" />
            </ReduxProvider>
        );
    }
}

export default wrapper.withRedux(Wrapper);
