import type { ICreateProjectDto, IProjectDto, IUpdateProjectDto } from "common/dist/dtos/ProfileDto";
import { Endpoint, HttpService } from "./util";

export class ProjectApi extends HttpService {
    @Endpoint()
    async update(projectId: number, project: IUpdateProjectDto): Promise<void> {
        return this.putRequest(`/api/project/${projectId}`, project);
    }

    @Endpoint()
    async delete(projectId: number): Promise<void> {
        await this.deleteRequest(`/api/project/${projectId}`);
    }

    @Endpoint()
    async list(userId: number): Promise<IProjectDto[]> {
        return await this.getRequest("/api/project", { userId });
    }

    @Endpoint()
    async create(project: ICreateProjectDto): Promise<IProjectDto> {
        return await this.postRequest("/api/project", project);
    }
}
