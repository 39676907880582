import api from "@/api";
import _ from "lodash";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServiceInfoDto } from "common/dist/dtos/service/ServiceDto";

export enum ServiceSortType {
    MostPopular,
    TopRated,
    New,
    MostRelevant
}

export interface IServicesState {
    services: IServiceInfoDto[] | null;
    sort: ServiceSortType;
}

const initialState: IServicesState = {
    services: [],
    sort: ServiceSortType.MostPopular
};

export const fetchServices = createAsyncThunk("services/fetch", async (categoryId: number) => {
    return await api.endpoints.service.listByCategory(categoryId);
});

export const services = createSlice({
    name: "services",
    initialState,
    reducers: {
        sortBy(state, action: PayloadAction<ServiceSortType>) {
            state.sort = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchServices.pending, (state) => {
                state.services = null;
            })
            .addCase(fetchServices.fulfilled, (state, res) => {
                state.services = res.payload;
            });
    }
});
