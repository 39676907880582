import { HttpService } from "./util";
import { CreateReviewDto, ReviewDto, Voting } from "common/dist/dtos/review.dto";
import { CallInfoDto, CancelCallDto } from "common/dist/dtos/call.dto";
import { CallStatus, ICallDto } from "common/dist/dtos/CallDto";

export class CallApi extends HttpService {
    get = (id: number) => this.getRequest<ICallDto>(`/api/call/${id}`);
    getBookedByProfessional = () => this.getRequest<ICallDto[]>("/api/call/professional");
    getBookedByClient = () => this.getRequest<ICallDto[]>("/api/call/client");

    getUpcoming = () => this.getRequest<CallInfoDto[]>(`/api/call/upcoming`);

    complete = (callId: number) => this.postRequest<CallStatus>(`/api/call/${callId}/complete`);
    cancel = (callId: number, dto: CancelCallDto) => this.postRequest<CallStatus>(`/api/call/${callId}/cancel`, dto);

    submitReview = (callId: number, message: string, voting: Voting) =>
        this.postRequest<ReviewDto, CreateReviewDto>(`/api/call/${callId}/review`, { message, voting });
}
