import { notification } from "antd";
import { getCookie } from "cookies-next";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export interface IApi {}

const delay = (t: number): Promise<void> =>
    new Promise((resolve) => {
        setTimeout(resolve, t);
    });

export const parseCookie = (str) =>
    str
        .split(";")
        .map((v) => v.split("="))
        .reduce((acc, v) => {
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent((v[1] || "").trim());
            return acc;
        }, {});

export class HttpService {
    axios: AxiosInstance;

    constructor(ctx = null) {
        const config: AxiosRequestConfig = {};

        // If on server, set correct domain
        if (typeof window == "undefined") {
            config.baseURL = process.env.API_DOMAIN;
        }

        if (ctx) {
            const accessToken = getCookie("access_token", ctx);
            if (accessToken) {
                config.headers = {
                    cookie: `access_token=${accessToken};`
                };
            }
        }

        this.axios = axios.create(config);
    }

    setHeaders(headers) {
        this.axios.defaults.headers = headers;
    }

    async getRequest<Response = void>(url: string, query: object = {}): Promise<Response> {
        const { data } = await this.axios.get(url, { params: query });
        return data as Response;
    }

    async postRequest<Response = void, Body = any>(
        url: string,
        body: Body = undefined,
        query: object = {}
    ): Promise<Response> {
        const { data } = await this.axios.post(url, body, { params: query });
        return data as Response;
    }

    async putRequest<Response = void, Body = any>(
        url: string,
        body: Body = undefined,
        query: object = {}
    ): Promise<Response> {
        const { data } = await this.axios.put(url, body, { params: query });
        return data as Response;
    }

    async deleteRequest<Response = void, Body = any>(url: string, body: Body = undefined): Promise<Response> {
        const { data } = await this.axios.delete(url, body);
        return data as Response;
    }
}

export function Endpoint(timeout = 10000) {
    return function (_target: any, _propertyKey: string, descriptor: PropertyDescriptor) {
        return;
        const method = descriptor.value as any;
        descriptor.value = async function (...args: any) {
            try {
                let timedOut = false;
                const result = await Promise.race([
                    delay(timeout).then((x) => (timedOut = true)),
                    method.call(this, ...args)
                ]);
                if (timedOut) {
                    throw new Error("timedout!");
                }
                return result;
            } catch (e) {
                notification.error({
                    message: "Endpoint call failed",
                    description: e.toString()
                });
                console.error(e);
                throw e;
            }
        };
    };
}
