import { SummaryDto } from "common/dist/dtos/review.dto";
import { CreateServiceDto } from "common/dist/dtos/service.dto";
import {
    IPackageDto,
    IServiceCardDto,
    IServiceDto,
    IServiceInfoDto,
    IUpdateServiceDto
} from "common/dist/dtos/service/ServiceDto";
import { Endpoint, HttpService } from "./util";
import { IUserInfoDto } from "common/dist/dtos/UserDto";

export class ServiceApi extends HttpService {
    get = (id: number) => this.getRequest<IServiceDto>(`/api/service/${id}`);
    getPackage = (id: number) => this.getRequest<IPackageDto>(`/api/service/package/${id}`);
    getInfo = (id: number) => this.getRequest<IServiceInfoDto>(`/api/service/${id}/info`);
    getEssentials = () => this.getRequest<IServiceInfoDto[]>(`/api/service/essentials`);

    getSellerInfo = (id: number) => this.getRequest<IUserInfoDto>(`/api/service/${id}/seller`);
    getBySlug = (username: string, slug: string) =>
        this.getRequest<IServiceDto>(`/api/service/slug`, { username, slug });

    listHot = (take?: number, sortBy?: string) =>
        this.getRequest<IServiceInfoDto[]>(`/api/service/hot`, {
            take,
            sortBy
        });
    listFavourites = () => this.getRequest<IServiceInfoDto[]>(`/api/service/fav`);
    listNew = () => this.getRequest<IServiceInfoDto[]>(`/api/service/new`);

    getSummary = (id: number) => this.getRequest<SummaryDto>(`/api/service/${id}/summary`);

    @Endpoint()
    async search(query: string): Promise<IServiceCardDto[]> {
        return await this.getRequest(`/api/service/search`, { q: query });
    }

    @Endpoint()
    async archive(serviceId: number): Promise<void> {
        await this.putRequest(`/api/service/${serviceId}/archive`);
    }

    @Endpoint()
    async setActive(serviceId: number, active: boolean): Promise<boolean> {
        return await this.putRequest(`/api/service/${serviceId}/active`, { value: active });
    }

    @Endpoint()
    async edit(serviceId: number, dto: IUpdateServiceDto): Promise<void> {
        return await this.putRequest(`/api/service/${serviceId}`, dto);
    }

    @Endpoint()
    async create(dto: CreateServiceDto): Promise<IServiceInfoDto> {
        return await this.postRequest("/api/service", dto);
    }

    @Endpoint()
    async listByCategory(categoryId: number): Promise<IServiceInfoDto[]> {
        return await this.getRequest(`/api/service/category/${categoryId}`);
    }

    @Endpoint()
    async forStaticSubCategory(): Promise<IServiceInfoDto[]> {
        return await this.getRequest(`/api/service/forStaticSubCategory`);
    }

    @Endpoint()
    async list(userId: number): Promise<IServiceInfoDto[]> {
        return await this.getRequest("/api/service", {
            userId
        });
    }

    @Endpoint()
    async listByName(): Promise<IServiceInfoDto[]> {
        return await this.getRequest("/api/service/list-by-name");
    }

    async listSelf(): Promise<IServiceInfoDto[]> {
        return await this.getRequest("/api/service/self");
    }
}
