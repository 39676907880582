import { initializeApp, FirebaseApp } from "firebase/app";
import { getAnalytics, logEvent, Analytics, setUserId, setUserProperties } from "firebase/analytics";
import React, { useContext } from "react";
import { PaymentMethod } from "common/dist/dtos/order.dto";
import _ from "lodash";
import { ISession } from "common/dist/dtos/ISession";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA72iyP0sBXzrmCWMf7muhsj2aZrGrOoOc",
    authDomain: "vettted.firebaseapp.com",
    projectId: "vettted",
    storageBucket: "vettted.appspot.com",
    messagingSenderId: "602245348274",
    appId: process.env.FIREBASE_APP_ID
};

export namespace Events {
    export interface IEvent {
        name: string;
    }

    export interface Checkout {
        name: "checkout";
        payment_method: PaymentMethod;
    }

    export interface LetsTalk {
        name: "lets_talk";
        profile_id: string;
    }

    export interface Signup {
        name: "sign_up";
        login_method: "google" | "password";
    }

    export interface Login {
        name: "login";
        login_method: "google" | "password";
    }

    export interface Purchase {
        name: "purchase";
    }

    export interface Search {
        name: "search";
        search_term: string;
    }

    export interface ViewService {
        name: "view_item";
        service_id: string;
    }

    export interface ViewProfile {
        name: "view_profile";
        profile_id: string;
    }

    export interface ViewConsulting {
        name: "view_consulting";
        profile_id: string;
    }

    export interface SearchNote {
        name: "search_note";
    }

    export interface WriteNote {
        name: "write_note";
    }

    export interface VerifyEmail {
        name: "verify_email";
    }

    export interface ViewReviewVideo {
        name: "view_review_video";
    }

    export interface CheckoutConsulting {
        name: "checkout_consulting";
    }

    export interface BeginCheckout {
        name: "begin_checkout";
        package_id: string;
        service_id: string;
    }

    export type Event =
        | BeginCheckout
        | Checkout
        | LetsTalk
        | Signup
        | Login
        | Purchase
        | Search
        | ViewService
        | ViewProfile
        | SearchNote
        | WriteNote
        | ViewConsulting
        | VerifyEmail
        | CheckoutConsulting
        | ViewReviewVideo;
}

export interface IMetrics {
    setSession(session: ISession): void;
    log(event: Events.Event): void;
}

export class Metrics implements IMetrics {
    private app: FirebaseApp;
    private analytics: Analytics;

    constructor() {
        this.app = initializeApp(firebaseConfig);
        this.analytics = getAnalytics(this.app);
    }

    setSession = (session: ISession | null) => {
        if (session) {
            setUserId(this.analytics, `${session.user.userId}`, { global: true });
            setUserProperties(
                this.analytics,
                {
                    user_dimension_id: session.user.userId,
                    user_name: session.user.name,
                    user_username: session.user.username,
                    user_professional: session.user.professional
                },
                { global: true }
            );
        } else {
            setUserId(this.analytics, null, { global: true });
            setUserProperties(this.analytics, {}, { global: true });
        }
    };

    log = (event: Events.IEvent) =>
        logEvent(this.analytics, event.name, {
            ..._.omit(event, ["name"]),
            app_env: process.env.APP_ENV
        });
}

export const MetricsContext = React.createContext<IMetrics>(null);
export const useMetricsContext = () => useContext(MetricsContext);
