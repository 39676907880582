import _ from "lodash";
import { endpoints } from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApplicationDto } from "common/dist/dtos/application/ApplicationDto";
import { IExperienceDto } from "common/dist/dtos/application/ExperienceDto";
import { RootState } from "..";

export interface IApplyState extends IApplicationDto {
    area: number;
}

const initialState: IApplyState = {
    image: "",
    fullname: "",
    tagline: "",
    country: "",
    city: "",
    area: null,
    portfolio: "",
    linkedin: "",
    experiences: [],
    username: ""
};

export const applySubmit = createAsyncThunk("apply/submit", async (_payload, store) => {
    const state = store.getState() as RootState;
    await endpoints.application.submit(state.apply);
});

export const apply = createSlice({
    name: "apply",
    initialState,
    reducers: {
        editPersonal(
            state,
            action: PayloadAction<
                Partial<{
                    image: string;
                    fullname: string;
                    tagline: string;
                    country: string;
                    city: string;
                    area: number;
                }>
            >
        ) {
            _.merge(state, action.payload);
        },
        editLinkedin(state, action: PayloadAction<string>) {
            state.linkedin = action.payload;
        },
        editPortfolio(state, action: PayloadAction<string>) {
            state.portfolio = action.payload;
        },
        editExperience(state, action: PayloadAction<{ index: number; edit: Partial<IExperienceDto> }>) {
            _.merge(state.experiences[action.payload.index], action.payload.edit);
        },
        removeExperience(state, action: PayloadAction<number>) {
            state.experiences.splice(action.payload, 1);
        },
        addExperience(state) {
            state.experiences.push({
                id: Date.now(),
                title: null,
                description: null,
                attachments: []
            });
        },
        setExperiences(state, action: PayloadAction<IExperienceDto[]>) {
            state.experiences = action.payload;
        },
        setAttachments(state, action: PayloadAction<{ experienceId: number; files: string[] }>) {
            state.experiences.forEach((experience) => {
                if (experience.id === action.payload.experienceId) {
                    experience.attachments = action.payload.files;
                }
            });
        },
        editVideo(state, action: PayloadAction<string>) {
            state.video = action.payload;
        }
    }
});
