import { Input } from "antd";
import { SearchProps } from "antd/lib/input";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
// import { SearchOutlined } from "@ant-design/icons";

export function SearchInput(props: SearchProps) {
    const router = useRouter();
    const [value, setValue] = useState(router.query.q);

    useEffect(() => setValue(router.query.q), [router.query.q]);

    const onSearch = (value: string) => {
        if (value) {
            router.push({ pathname: '/services/search', query: { q: value } });
        } else {
            router.push({ pathname: '/services/search' })
        }
    };

    return (
        <Input.Search
            {...props}
            defaultValue={router.query.q as string}
            placeholder={props.placeholder}
            onSearch={onSearch}
            value={value}
            onChange={(e) => setValue(e.target.value)}
        />
    );
}