import type { IApplicationDto } from "common/dist/dtos/application/ApplicationDto";
import { Endpoint, HttpService } from "./util";

export class ApplicationApi extends HttpService {
    hasPending = () => this.getRequest<boolean>(`/api/apply/pending`);

    @Endpoint()
    async accept(id: number): Promise<void> {
        await this.postRequest(`/api/apply/${id}/accept`);
    }

    @Endpoint()
    async reject(id: number): Promise<void> {
        await this.postRequest(`/api/apply/${id}/reject`);
    }

    @Endpoint()
    async list(): Promise<IApplicationDto[]> {
        return await this.getRequest("/api/apply");
    }

    @Endpoint()
    async delete() {
        await this.deleteRequest("/api/apply");
    }

    @Endpoint()
    async submit(dto: IApplicationDto) {
        await this.postRequest("/api/apply", dto);
    }
}
