import { HttpService } from "./util";
import {
    CampaignDto,
    CampaignGSCDataDto,
    CampaignGSCDto,
    CampaignMetricsResponseDto,
    CreateCampaignDto
} from "common/dist/dtos/campaign.dto";
import { OrderInfoDto } from "common/dist/dtos/order.dto";

export class CampaignApi extends HttpService {
    all = () => this.getRequest<CampaignDto[]>(`/api/campaign`);
    add = (dto: CreateCampaignDto) => this.postRequest<CampaignDto, CreateCampaignDto>(`/api/campaign`, dto);
    assign = (id: string, orderId: string) => this.postRequest<CampaignDto>(`/api/campaign/${id}/order/${orderId}`);
    unassign = (orderId: string) => this.deleteRequest(`/api/campaign/order/${orderId}`);
    remove = (id: string) => this.deleteRequest(`/api/campaign/${id}`);
    orders = (id: string) => this.getRequest<OrderInfoDto[]>(`/api/campaign/${id}/order`);
    metrics = (id: string) => this.getRequest<CampaignMetricsResponseDto>(`/api/campaign/${id}/metrics`);
    sites = (id: string) => this.getRequest<CampaignGSCDto>(`/api/campaign/${id}/sites`);
    gscData = (url: string, start: string, end: string) =>
        this.getRequest<CampaignGSCDataDto>(`/api/campaign/gsc`, { start, end, url });
    assignGsc = (id: string, site: string) => this.putRequest(`/api/campaign/${id}/gsc`, { gscSiteUrl: site });
}
