import React, { useState, useEffect, useContext, useMemo, useCallback, DependencyList } from "react";
import { useRouter } from "next/router";
import { Divider, InputNumber, InputNumberProps } from "antd";
import _ from "lodash";
import produce, { Draft } from "immer";
import { CommonStore } from "@/common/store/common.store";
import { ICategoryWithParentDto } from "common/dist/dtos/ICategoryDto";
import Cascader, { CascaderProps, DefaultOptionType } from "antd/lib/cascader";
import { trace } from "common/dist/util";
import { useSessionContext } from "@/common/session.context";
import { NextPage } from "next";

export const getYouTubeVideoIdFromUrl = (url: string) => {
    // Our regex pattern to look for a youTube ID
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    //Match the url with the regex
    const match = url.match(regExp);
    //Return the result
    return match && match[2].length === 11 ? match[2] : undefined;
};

export function useLoading<T extends (...args: any[]) => any>(fn: T, deps: DependencyList) {
    const [loading, setLoading] = useState(false);
    const callback = useCallback(
        async (...args) => {
            setLoading(true);
            try {
                return await fn(...args);
            } finally {
                setLoading(false);
            }
        },
        [loading, setLoading, ...deps]
    );
    return { loading, callback: callback as T };
}

export function displayCity(obj: { country: string; city: string }) {
    return _.compact([obj.city, obj.country]).join(", ");
}

export function isImage(src: string) {
    if (!src) {
        return false;
    }
    const url = new URL(src);
    const p = url.pathname.split(".");
    return ["jpeg", "png", "jpg", "gif"].includes(p[p.length - 1].toLowerCase());
}

export const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
});

export const CommonContext = React.createContext<CommonStore>(null);
export const useCommonContext = () => useContext(CommonContext);

export interface IMoneyInputProps extends Omit<InputNumberProps, "formatter"> {}

export function MoneyInput(props: IMoneyInputProps) {
    return <InputNumber precision={0} min={0} {...props} />;
}

export function isBrowser() {
    return typeof window !== "undefined";
}

// TODO: Change this to middlewares
export const withAuth = (Component: NextPage<any>, loggedRequiredValue: boolean) => {
    const WrappedComponent = (props) => {
        const router = useRouter();
        const session = useSessionContext();
        if (isBrowser() && !!session !== loggedRequiredValue) {
            router.push("/");
            return null;
        } else {
            return <Component {...props} />;
        }
    };

    WrappedComponent.getInitialProps = async (ctx) => {
        if (ctx.req) {
            const cookie = ctx.req.headers.cookie || "";
            if (cookie.includes("access_token") !== loggedRequiredValue) {
                ctx.res.writeHead(302, { Location: "/" });
                ctx.res.end();
            }
        }

        let pageProps = {};
        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }

        return pageProps;
    };

    return WrappedComponent;
};

export const withLogin = (Component: NextPage<any>) => withAuth(Component, true);
export const withoutLogin = (Component: NextPage<any>) => withAuth(Component, false);

export interface IDivideProps {
    top?: boolean;
    children: React.ReactNode | React.ReactNode[];
}

export function Divide(props: IDivideProps) {
    return (
        <>
            {_.castArray(props.children).map(
                (x, i) =>
                    x && (
                        <div key={i}>
                            {(props.top || i > 0) && <Divider dashed />} {x}
                        </div>
                    )
            )}
        </>
    );
}

export function usePromise<D>(promise: () => Promise<D>, deps: any[] = []) {
    const [i, setI] = useState(0);
    const [state, setState] = useState({
        loading: true,
        data: undefined as D,
        error: undefined
    });
    useEffect(() => {
        setState((state) => ({ ...state, loading: true }));
        promise().then((x) => {
            setState((state) => ({ ...state, loading: false, data: x }));
        });
    }, [...deps, setState, i]);
    return {
        ...state,
        refresh: () => setI((i) => i + 1),
        update: (getData: (_: D) => D) =>
            setState((state) => ({
                ...state,
                data: getData(state.data)
            }))
    };
}

export function useImmer<T>(value: T) {
    const [state, setState] = useState(value);
    const setImmerState = (fn: (draft: Draft<T>) => void) => setState((st) => produce(st, fn));
    return [state, setImmerState] as const;
}

export function convertToCascader(categories: ICategoryWithParentDto[], depth = Infinity): DefaultOptionType[] {
    const childrenOf = _.groupBy(categories, (c) => c.parentId || 0);

    const walk = (c: ICategoryWithParentDto, level: number): DefaultOptionType => {
        return {
            value: c.id,
            label: c.name,
            level,
            children: level >= depth ? [] : (childrenOf[c.id] || []).map((x) => walk(x, level + 1))
        };
    };

    return childrenOf[0]?.map((val) => walk(val, 1)) ?? [];
}

export function CustomCascader({ value, ...props }: CascaderProps<DefaultOptionType>) {
    const getCascaderValue = (id: number) =>
        trace(
            props.options || [],
            (x) => x.children || [],
            (x) => x.value === id
        ).map((x) => x.value);
    const cascaderValue = useMemo(() => getCascaderValue(value as unknown as number), [value, props.options]);
    return <Cascader<DefaultOptionType> {...props} value={cascaderValue} className="ant-select-in-form-item" />;
}

export const shortName = (name: string) => {
    const names = name.match(/[^\s]+/g).map(_.capitalize);
    const first = names[0];
    const last = _.last(names);
    return `${first} ${last[0]}.`;
};
