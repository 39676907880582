import { IConsultingDto } from "common/dist/dtos/ConsultingDto";
import { Endpoint, HttpService } from "./util";
import { SummaryDto } from "common/dist/dtos/review.dto";
import { IProfessionalDto } from "common/dist/dtos/ProfessionalDto";

export class ProfessionalApi extends HttpService {
    list = () => this.getRequest(`/api/professional`);
    auth = () => this.getRequest(`/api/professional/auth`);
    listNew = () => this.getRequest<IProfessionalDto[]>(`/api/professional/new`);
    listPopular = (take?: number) => this.getRequest<IProfessionalDto[]>(`/api/professional/popular`, { take });
    listRising = () => this.getRequest<IProfessionalDto[]>(`/api/professional/rising`);
    getSummary = (id: number) => this.getRequest<SummaryDto>(`/api/professional/${id}/summary`);
    getConsultingSummary = (id: number) => this.getRequest<SummaryDto>(`/api/professional/${id}/consultingSummary`);
    getSelfConsulting = (): Promise<IConsultingDto | null> => this.getRequest(`/api/consulting`);

    @Endpoint()
    async getConsulting(id: number): Promise<IConsultingDto | null> {
        return await this.getRequest(`/api/professional/${id}/consulting`);
    }
}
