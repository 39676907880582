import {
    AnyAction,
    combineReducers,
    configureStore,
    createAsyncThunk,
    createSlice,
    PayloadAction
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { createWrapper, HYDRATE, MakeStore } from "next-redux-wrapper";
import { admin } from "./slices/admin";
import { apply } from "./slices/apply";
import { profile } from "./slices/profile";
import { directory } from "./slices/directory";
import { services } from "./slices/services";
import { endpoints } from "@/api";
import { getCookie } from "cookies-next";

export interface IIdentityState {
    loggedIn: boolean;
    username: string;
}

export const signout = createAsyncThunk("slice/signout", async () => {
    await endpoints.identity.logout();
    message.success("Logged out");
});

export const signin = createAsyncThunk("slice/signin", async (payload: { username: string; password: string }, api) => {
    const response = await endpoints.identity.loginWithPassword(payload.username, payload.password);
    if ("error" in response) {
        message.error(response.error);
    } else {
        //api.dispatch(enter({ username: response.username }));
        //api.dispatch(profile.actions.init({ image: response.image }));
        window.open("/", "_self");
    }
});

export const signinWithGoogle = createAsyncThunk("slice/signin-google", async (payload: { token: string }, api) => {
    const response = await endpoints.identity.loginWithGoogle(payload.token);
    if ("error" in response) {
        message.error(response.error);
    } else {
        const redirect = (getCookie("before_signup") || "/").toString();
        window.open(redirect, "_self");
    }
});

export const identity = createSlice({
    name: "slice",
    initialState: {
        loggedIn: false,
        username: "",
        email: ""
    } as IIdentityState,
    reducers: {
        enter(state, action: PayloadAction<{ username: string }>) {
            state.username = action.payload.username;
            state.loggedIn = true;
        }
    },
    extraReducers(builder) {
        builder.addCase(signout.fulfilled, (state) => {
            state.loggedIn = false;
        });
    }
});

export const { enter } = identity.actions;

const combineReducer = combineReducers({
    identity: identity.reducer,
    profile: profile.reducer,
    apply: apply.reducer,
    admin: admin.reducer,
    directory: directory.reducer,
    services: services.reducer
});

export type RootState = ReturnType<typeof combineReducer>;

function rootReducer(state: RootState, action: AnyAction) {
    switch (action.type) {
        case HYDRATE:
            return action.payload as undefined;
        default:
            return combineReducer(state, action);
    }
}

export const store = configureStore({
    reducer: rootReducer
});

const makeStore: MakeStore<any> = () => store;

export const wrapper = createWrapper(makeStore);

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppStore = typeof store;
