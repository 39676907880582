import React, { PropsWithChildren } from "react";
import { Space } from "antd";
import Icon from "@ant-design/icons";
import Link from "next/link";
import { Logo } from "@/components/TopBar";
import Container from "@/components/Container";
import TwitterSvg from "../../public/social/twitter.svg";
import YoutubeSvg from "../../public/social/youtube.svg";

interface IItem {
    name: string;
    url: string;
}

interface ISection {
    name: string;
    items: IItem[];
}

const sections: ISection[] = [
    {
        name: "Services",
        items: [
            { name: "On-Page SEO", url: "/on-page-seo" },
            {
                name: "Off-Page SEO",
                url: "/off-page-seo"
            },
            {
                name: "Technical SEO",
                url: "/technical-seo"
            }
        ]
    },
    {
        name: "Resources",
        items: [
            { name: "Blog", url: "/blog" },
            { name: "Contact Support", url: "mailto:help@vettted.com" },
            { name: "Knowledge Base", url: "https://vettted.tawk.help" }
        ]
    },
    {
        name: "About",
        items: [
            { name: "Terms of use", url: "/terms" },
            { name: "Privacy Policy", url: "/privacy-policy" }
        ]
    }
];

function Trademark() {
    const buildTime = new Date(process.env.BUILD_TIME);

    return (
        <div className="text-gray-400">
            <span className="text-indigo-500">2024</span> Vettted. All rights reserved.
            <p className="text-white text-opacity-10 text-xs">
                {buildTime.toLocaleString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: true
                })}
            </p>
        </div>
    );
}

const SocialIcon = (props: PropsWithChildren<{ component: any }>) => (
    <div
        className="rounded-full grid justify-center items-center cursor-pointer text-white"
        style={{
            width: "36px",
            height: "36px",
            background: "rgba(255, 255, 255, 0.24)",
            borderColor: "rgba(255, 255, 255, 0.16)"
        }}
    >
        <Icon component={props.component} />
    </div>
);

function Socials() {
    return (
        <div className="flex gap-4" style={{ fontSize: "32px", lineHeight: "48px" }}>
            <Link href="https://twitter.com/vettted">
                <a target="_blank">
                    <SocialIcon component={TwitterSvg} />
                </a>
            </Link>
            <Link href="https://youtube.com/channel/UCjzhXEU6g9biIoOTWw8VSbA">
                <a target="_blank">
                    <SocialIcon component={YoutubeSvg} />
                </a>
            </Link>
        </div>
    );
}

export function Footer() {
    return (
        <div className="py-10" style={{ background: "#100E26" }}>
            <Container>
                <div className="flex justify-between gap-5 flex-col sm:flex-row">
                    <div className="space-y-5">
                        <Logo dark={false} />
                        <Trademark />
                    </div>
                    <div className="grid grid-cols-3 gap-5">
                        {sections.map((x, i) => (
                            <div key={i}>
                                <p className="text-white">{x.name}</p>
                                <Space direction="vertical">
                                    {x.items.map((item, i) => (
                                        <Link href={item.url} key={i}>
                                            <a
                                                target={
                                                    item.name === "Blog" || item.name === "Knowledge Base"
                                                        ? "_blank"
                                                        : "_self"
                                                }
                                            >
                                                {item.name}
                                            </a>
                                        </Link>
                                    ))}
                                </Space>
                            </div>
                        ))}
                    </div>
                    <div className="grid gap-5">
                        <Socials />
                    </div>
                </div>
            </Container>
        </div>
    );
}
