import { PropsWithChildren } from "react";

export interface IContainerProps {
    className?: string;
    style?: object;
}

export default function Container(props: PropsWithChildren<IContainerProps>) {
    return (
        <div style={props.style} className={`px-5 mx-auto max-w-screen-xl ${props.className}`}>
            {props.children}
        </div>
    );
}
