import type { IOrderDto } from "common/dist/dtos/IOrderDto";
import { HttpService } from "./util";
import {
    CancelOrderDto,
    DeliveriesDto,
    DeliveryDto,
    EditNoteDto,
    OrderInfoDto,
    OrderPricingDataDto,
    OrderStatus,
    OrdersWithTotal,
    RequirementsDto,
    RevisionDto
} from "common/dist/dtos/order.dto";
import { IAttachmentDto, IChannelDto } from "common/dist/dtos/chat/ChatDto";
import { CreateReviewDto, ReviewDto, Voting } from "common/dist/dtos/review.dto";

export class OrderApi extends HttpService {
    getUpcoming = () => this.getRequest<OrderInfoDto[]>(`/api/order/upcoming`);

    getUnacknowledged = () => this.getRequest<OrderInfoDto[]>(`/api/order/unacknowledged`);

    submitReview = (orderId: string, message: string, voting: Voting) =>
        this.postRequest<ReviewDto, CreateReviewDto>(`/api/order/${orderId}/review`, { message, voting });

    cancelIfLate = (orderId: string) => this.postRequest<OrderStatus>(`/api/order/${orderId}/cancelIfLate`);

    acknowledge = (orderId: string) => this.postRequest<boolean>(`/api/order/${orderId}/acknowledge`);

    getChannel = (orderId: string) => this.getRequest<IChannelDto>(`/api/order/${orderId}/chat`);

    getChannelWithAdmin = (orderId: string) => this.getRequest<IChannelDto>(`/api/order/${orderId}/adminChat`);

    cancel = (orderId: string, reason: string) =>
        this.postRequest<OrderStatus, CancelOrderDto>(`/api/order/${orderId}/cancel`, { reason });

    complete = (orderId: string) => this.postRequest(`/api/order/${orderId}/complete`);

    async submitRevision(orderId: string, message: string): Promise<RevisionDto> {
        return await this.postRequest(`/api/order/${orderId}/revision`, { message });
    }

    async getDeliveries(orderId: string): Promise<DeliveriesDto> {
        return await this.getRequest(`/api/order/${orderId}/delivery`);
    }

    async submitDelivery(orderId: string, message: string, attachments: IAttachmentDto[]): Promise<DeliveryDto> {
        const data = {
            message,
            attachments
        };
        return await this.postRequest(`/api/order/${orderId}/delivery`, data);
    }

    async getRequirements(orderId: string): Promise<RequirementsDto> {
        return await this.getRequest(`/api/order/${orderId}/requirements`);
    }

    async editNote(orderId: string, note: string): Promise<void> {
        await this.putRequest<void, EditNoteDto>(`/api/order/${orderId}/note`, { note });
    }

    async list({ skip = 0, take = 10 }: { skip?: number; take?: number }): Promise<OrdersWithTotal> {
        const url = `/api/order?skip=${skip}&take=${take}`;
        return await this.getRequest(url);
    }

    async submitRequirements(orderId: string, requirements: string, attachments: IAttachmentDto[]): Promise<string> {
        const data = {
            requirements,
            attachments
        };

        return await this.postRequest(`/api/order/${orderId}/requirements`, data);
    }

    async get(orderId: string): Promise<IOrderDto> {
        return await this.getRequest(`/api/order/${orderId}`);
    }

    pricing = (orderId: string): Promise<OrderPricingDataDto> => this.getRequest(`/api/order/${orderId}/pricing`);
}
