import { bankAccount } from "mangopay2-nodejs-sdk";
import { BankAccountCreationDto } from "common/dist/dtos/bank.dto";
import { HttpService } from "./util";

export class BankApi extends HttpService {
    setDefault = (id: string) => this.postRequest<bankAccount.Data>(`/api/bank/${id}/default`);
    getDefault = () => this.getRequest<string>(`/api/bank/default`);
    list = () => this.getRequest<bankAccount.Data[]>(`/api/bank`);
    add = (dto: BankAccountCreationDto) => this.postRequest<bankAccount.Data, BankAccountCreationDto>(`/api/bank`, dto);
    desactivate = (id: string) => this.postRequest<bankAccount.Data>(`/api/bank/${id}/desactivate`);
}
