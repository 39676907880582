"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceStatus = exports.PackageInfoDto = exports.ICreatePackageDto = exports.PackageType = void 0;
var PackageType;
(function (PackageType) {
    PackageType["Standard"] = "standard";
    PackageType["Advanced"] = "advanced";
    PackageType["Premium"] = "premium";
})(PackageType = exports.PackageType || (exports.PackageType = {}));
var ICreatePackageDto = /** @class */ (function () {
    function ICreatePackageDto() {
    }
    return ICreatePackageDto;
}());
exports.ICreatePackageDto = ICreatePackageDto;
var PackageInfoDto = /** @class */ (function (_super) {
    __extends(PackageInfoDto, _super);
    function PackageInfoDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PackageInfoDto;
}(ICreatePackageDto));
exports.PackageInfoDto = PackageInfoDto;
var ServiceStatus;
(function (ServiceStatus) {
    ServiceStatus["Approved"] = "approved";
    ServiceStatus["Pending"] = "pending";
    ServiceStatus["Denied"] = "denied";
})(ServiceStatus = exports.ServiceStatus || (exports.ServiceStatus = {}));
