import { HttpService } from "./util";
import {
    ClearanceEarningDto,
    ConsultingDto,
    CustomerEarningDto,
    EarningsSummaryDto,
    ServiceEarningDto
} from "common/dist/dtos/revenue.dto";
import { Transaction } from "backend/dist/repository/models";

export class RevenueApi extends HttpService {
    withdraw = () => this.postRequest(`/api/revenue/withdraw`);
    getSummary = () => this.getRequest<EarningsSummaryDto>(`/api/revenue/summary`);
    getByClearance = () => this.getRequest<ClearanceEarningDto[]>(`/api/revenue/clearance`);
    getWithDrawals = () => this.getRequest<Transaction[]>(`/api/revenue/withdrawals`);
    getConsulting = () => this.getRequest<ConsultingDto[]>(`/api/revenue/consulting`);
    getByService = () => this.getRequest<ServiceEarningDto[]>(`/api/revenue/service`);
    getByCustomer = () => this.getRequest<CustomerEarningDto[]>(`/api/revenue/customer`);
}
