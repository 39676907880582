import { IBillingInfoDto } from "common/dist/dtos/UserDto";
import { HttpService } from "./util";
import { HeroDto } from "common/dist/dtos/hero.dto";
import { ContactUsDto } from "common/dist/dtos/contact.dto";

export class CommonApi extends HttpService {
    getHeros = () => this.getRequest<HeroDto[]>("/api/professional/hero");
    getAccountDetails = () => this.getRequest<{ username: string; email: string }>(`/api/account`);
    getAccountBillingDetails = () => this.getRequest<IBillingInfoDto>(`/api/account/billing`);
    saveAccountBillingDetails = (billing: IBillingInfoDto) => this.postRequest(`/api/account/billing`, billing);
    isCalendarConnected = () => this.getRequest<boolean>(`/api/calendar/connected`);
    contact = (dto: ContactUsDto) => this.postRequest(`/api/user/contact`, dto);
}
