import "reflect-metadata";

import axios from "axios";
import https from "https";
import http from "http";
import _ from "lodash";
import { ApplicationApi } from "./ApplicationApi";
import { ChatApi } from "./ChatApi";
import type { IConsultingDto, IUpdateConsultingDto } from "common/dist/dtos/ConsultingDto";
import type { BookCallDto, IBookCallResponseDto, SlotRequestDto, ISlotResponseDto } from "common/dist/dtos/CallDto";
import type { ConnectGoogleDto } from "common/dist/dtos/GoogleDto";
import type { IProfileDto } from "common/dist/dtos/ProfileDto";
import type { ICreateUserDto, ICreateUserGoogleDto } from "common/dist/dtos/UserDto";
import { ProfessionalApi } from "./professional.api";
import { CategoryApi } from "./CategoryApi";
import { ProjectApi } from "./ProjectApi";
import { ServiceApi } from "./ServiceApi";
import { CheckoutApi } from "./CheckoutApi";
import { OrderApi } from "./order.api";
import { CallApi } from "./call.api";
import { RevenueApi } from "./revenue.api";
import { CommonApi } from "./common.api";
import { BankApi } from "./bank.api";
import { AnalyticsApi } from "./analytics.api";
import { IdentityApi } from "./identity.api";
import { AccountApi } from "./account.api";
import { CalendarApi } from "./calendar.api";
import { UserApi } from "./user.api";
import { MangopayApi } from "./mangopay.api";
import { CouponApi } from "./coupon.api";
import { CartApi } from "./cart.api";
import { WalletApi } from "./wallet.api";
import { CampaignApi } from "./campaign.api";
import { TrolleyApi } from "./trolley.api";

export function getEndpoints(headers = null) {
    return {
        campaign: new CampaignApi(headers),
        coupon: new CouponApi(headers),
        user: new UserApi(headers),
        calendar: new CalendarApi(headers),
        account: new AccountApi(headers),
        identity: new IdentityApi(headers),
        analytics: new AnalyticsApi(headers),
        revenue: new RevenueApi(headers),
        call: new CallApi(headers),
        service: new ServiceApi(headers),
        project: new ProjectApi(headers),
        professional: new ProfessionalApi(headers),
        application: new ApplicationApi(headers),
        chat: new ChatApi(headers),
        category: new CategoryApi(headers),
        checkout: new CheckoutApi(headers),
        order: new OrderApi(headers),
        common: new CommonApi(headers),
        bank: new BankApi(headers),
        mangopay: new MangopayApi(headers),
        cart: new CartApi(headers),
        wallet: new WalletApi(headers),
        trolley: new TrolleyApi(headers)
    };
}

export const endpoints = getEndpoints();

if (typeof window === "undefined") {
    axios.defaults.httpsAgent = new https.Agent({ keepAlive: true, maxSockets: Number.MAX_VALUE });
    axios.defaults.httpAgent = new http.Agent({ keepAlive: true, maxSockets: Number.MAX_VALUE });
    axios.defaults.baseURL = process.env.API_DOMAIN;
}

/** =============================================== */

// TODO: better organize this on proper services
export async function uploadImage(image: File): Promise<string> {
    const formData = new FormData();
    formData.append("image", image);
    return (
        await axios.post("/api/resource/image", formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
    ).data;
}

export async function signup(dto: ICreateUserDto): Promise<{ error?: string }> {
    try {
        await axios.post("/api/identity/signup", dto);
    } catch (e) {
        if (e.response) {
            return { error: e.response.data.message };
        }
    }
    return {};
}

export async function signupGoogle(dto: ICreateUserGoogleDto): Promise<{ error?: string }> {
    try {
        await axios.post("/api/identity/signupGoogle", dto);
    } catch (e) {
        if (e.response) {
            return { error: e.response.data.message };
        }
    }
    return {};
}

export async function getProfile(username: string) {
    return (await axios.get(`/api/profile/${username}`)).data;
}

export async function updateProfile(dto: Partial<IProfileDto>) {
    return (await axios.put("/api/profile", dto)).data;
}

export async function connectGoogle(dto: ConnectGoogleDto) {
    await axios.post("/api/google", dto);
}

export async function getTimeslots(dto: SlotRequestDto): Promise<ISlotResponseDto> {
    return (await axios.post("/api/call/slot", dto)).data;
}

export async function bookConsultingCall(dto: BookCallDto): Promise<IBookCallResponseDto> {
    return await (
        await axios.post("/api/call", dto)
    ).data;
}

export async function becomeProfessional() {
    await axios.post("/api/professional");
}

export async function unbecomeProfessional() {
    await axios.delete("/api/professional");
}

export async function updateConsulting(dto: IUpdateConsultingDto): Promise<IConsultingDto> {
    return (await axios.put("/api/consulting", dto)).data;
}

export async function getConsulting(): Promise<IConsultingDto> {
    return (await axios.get("/api/consulting")).data;
}
