import { IAttachmentDto, IChannelDto, IReceiveMessageDto, ISendMessageDto } from "common/dist/dtos/chat/ChatDto";
import { Endpoint, HttpService } from "./util";
import Pusher, { PresenceChannel } from "pusher-js";
import PrivateChannel from "pusher-js/types/src/core/channels/private_channel";

export interface ISubscription {
    unsubscribe(): void;
}

export interface IPrivateSubscriptionHandlers {
    onMessage(message: IReceiveMessageDto): void;
}

export interface IGlobalSubscriptionHandlers {
    onOnline(user: number): void;
    onOffline(user: number): void;
    onStart(users: number[]): void;
}

export class ChatApi extends HttpService {
    static pusher: Pusher;

    private presenceChannel: PresenceChannel;
    private privateChannel: PrivateChannel;

    constructor(headers = null) {
        super(headers);
        if (typeof window !== "undefined" && !ChatApi.pusher) {
            ChatApi.pusher = new Pusher(process.env.PUSHER_KEY, {
                cluster: process.env.PUSHER_CLUSTER,
                authEndpoint: "/api/chat/auth"
            });
        }
    }

    subscribeGlobal(): ISubscription {
        this.presenceChannel = ChatApi.pusher.subscribe("presence-status") as PresenceChannel;
        /*
        this.presenceChannel.bind("pusher:subscription_succeeded", () => {
            const onlineUsers = [];
            this.presenceChannel.members.each((m) => {
                onlineUsers.push(+m.id);
            });
            handlers.onStart(onlineUsers);
        });

        this.presenceChannel.bind("pusher:member_removed", (m) => handlers.onOffline(+m.id));
        this.presenceChannel.bind("pusher:member_added", (m) => handlers.onOnline(+m.id));
        */
        return {
            unsubscribe: () => ChatApi.pusher.unsubscribe("presence-status")
        };
    }

    subscribePrivate(
        username: string,
        handlers: {
            onMessage(message: IReceiveMessageDto): void;
        }
    ): ISubscription {
        if (!this.privateChannel) {
            this.privateChannel = ChatApi.pusher.subscribe(`private-${username}`) as PrivateChannel;
        }
        this.privateChannel.bind("message", handlers.onMessage);
        return {
            unsubscribe: () => this.privateChannel.unbind("onMessage", handlers.onMessage)
        };
    }

    markAsRead = (channel: string) => this.postRequest(`/api/chat/${channel}/read`);
    to = (to: number) => this.getRequest<string>(`/api/chat/to/${to}`);

    getAllChannels = () => this.getRequest<IChannelDto[]>(`/api/chat`);

    @Endpoint()
    async getAllMessages(channelKey: string): Promise<IReceiveMessageDto[]> {
        return await this.getRequest(`/api/chat/${channelKey}/message`);
    }

    @Endpoint()
    async send(channelKey: string, message: ISendMessageDto): Promise<IReceiveMessageDto> {
        return await this.postRequest(`/api/chat/${channelKey}/message`, message);
    }
}
