import _ from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDirectoryState {
    online: number[];
}

const initialState: IDirectoryState = {
    online: []
};

export const directory = createSlice({
    name: "directory",
    initialState,
    reducers: {
        start(state, { payload: users }: PayloadAction<number[]>) {
            state.online = users;
        },
        online(state, { payload: userId }: PayloadAction<number>) {
            state.online.push(userId);
        },
        offline(state, { payload: userId }: PayloadAction<number>) {
            _.pull(state.online, userId);
        }
    }
});
