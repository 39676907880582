import { IChangeEmailDto, IChangeIdentityDto, IRecoverPasswordDto, IUserInfoDto } from "common/dist/dtos/UserDto";
import { GoogleLoginDto } from "common/dist/dtos/identity.dto";
import { HttpService } from "./util";
import { IErrorDto } from "common/dist/dtos/ErrorDto";
import { ISession } from "common/dist/dtos/ISession";

export class IdentityApi extends HttpService {
    verify = (token: string) => this.postRequest<IUserInfoDto | IErrorDto>(`/api/identity/verify/${token}`);
    loginWithToken = (token: string) => this.postRequest<IUserInfoDto | IErrorDto>(`/api/identity/login/${token}`);
    login = (username: string) => this.postRequest<IErrorDto | {}>(`/api/identity/login`, { username });
    loginWithPassword = (username: string, password: string) =>
        this.postRequest<IErrorDto | {}>(`/api/identity/loginWithPassword`, {
            username,
            password
        });
    loginWithGoogle = (token: string) =>
        this.postRequest<IUserInfoDto | IErrorDto, GoogleLoginDto>(`/api/identity/loginGoogle`, {
            token
        });
    forgotPassword = (username: string) =>
        this.postRequest<IErrorDto | {}>(`/api/identity/forgotPassword`, { username });
    recoverPassword = (dto: IRecoverPasswordDto) => this.postRequest<void>(`/api/identity/recoverPassword`, dto);
    logout = () => this.postRequest(`/api/identity/logout`);
    session = () => this.getRequest<ISession>(`/api/identity/session`);
    refreshToken = () => this.getRequest<ISession & { token: string }>(`/api/identity/refreshToken`);
    changeEmail = (dto: IChangeIdentityDto) => this.postRequest<{ error: string }>(`/api/identity/changeEmail`, dto);
    confirmNewEmail = (dto: IChangeEmailDto) =>
        this.postRequest<{ error: string }>(`/api/identity/confirmNewEmail`, dto);
    changePassword = (dto: IChangeIdentityDto) =>
        this.postRequest<{ error: string }>(`/api/identity/changePassword`, dto);
}
